<script setup lang="ts">
import Header from '~/client/layouts/header.vue';
import Footer from '~/client/layouts/footer.vue';
import { useBoardStore } from '~/store/boardStore';

const slot = ref();
const boardStore = useBoardStore();
const nuxtApp = useNuxtApp();

console.log(boardStore);
onMounted(() => {
  console.log(slot);
})

function testEvent(){
  console.log('event');
}

console.log(nuxtApp);

</script>

<template>
    <Header></Header>     <!-- 헤더 영역 -->
    <slot ref="slot" @isLoading="testEvent"></slot>         <!-- 바디 영역 -->
    <Footer></Footer>     <!-- 푸터 영역 -->
</template>

<style lang="scss" scoped>
</style>